"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
const defaultResult = {
    vatValue: undefined,
    vatPercent: undefined,
    amount: undefined,
    netAmount: undefined,
    type: null
};
class VatcalculatorCtrl {
    constructor($scope, $filter, ConfigService, actionService) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.ConfigService = ConfigService;
        this.actionService = actionService;
        this.defaultVat = 20;
        this.vat = JSON.parse(localStorage.getItem(`${this.constructor.name}_vat`) || '{}').vat || this.defaultVat;
        this.result = Object.assign({}, defaultResult);
    }
    $onInit() {
        this.$scope.$watch('$ctrl.vat', (vat) => {
            localStorage.setItem(`${this.constructor.name}_vat`, JSON.stringify({ 'vat': vat }));
        });
    }
    removeVat() {
        var _a;
        if (((_a = this.vatForm) === null || _a === void 0 ? void 0 : _a.$valid) && (this.amount != undefined) && (this.vat != undefined)) {
            const netAmount = (this.amount / (100 + this.vat)) * 100;
            this.result = {
                amount: this.amount,
                vatValue: this.amount - netAmount,
                vatPercent: this.vat,
                netAmount: netAmount,
                type: 'remove'
            };
        }
        else {
            this.result = Object.assign(Object.assign({}, defaultResult), { type: 'remove' });
        }
    }
    addVat() {
        var _a;
        if (((_a = this.vatForm) === null || _a === void 0 ? void 0 : _a.$valid) && (this.amount != undefined) && (this.vat != undefined)) {
            const vat = this.vat * this.amount / 100;
            this.result = {
                amount: this.amount + vat,
                vatValue: vat,
                vatPercent: this.vat,
                netAmount: this.amount,
                type: 'add'
            };
        }
        else {
            this.result = Object.assign(Object.assign({}, defaultResult), { type: 'add' });
        }
    }
    copy(id, value) {
        this.actionService.copyFactory(document.getElementById(id), this.$filter('number')(value, 2));
    }
    clear() {
        if (this.vatForm) {
            this.vatForm.$setPristine();
            this.vatForm.$setUntouched();
            this.result = Object.assign({}, defaultResult);
        }
        this.amount = undefined;
        this.vat = this.vat != undefined ? this.vat : this.defaultVat;
    }
}
VatcalculatorCtrl.$inject = ['$scope', '$filter', 'ConfigService', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameVatcalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: VatcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('vatcalculator/');
    }]);
